import { ManOutlined, WomanOutlined } from "@ant-design/icons";
import {
  Button,
  Form,
  Input,
  InputNumber,
  Radio,
  Select,
  notification,
} from "antd";
import FormBulkPricingSetting from "app/components/Form/FormBulkPricingSetting";
import FadeIn from "app/components/Shape/FadeIn";
import { useRuleStringRequired } from "app/utils/FormValidation";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import AddressForm from "../../components/Address/AddressForm";
import { Gender, User } from "../../models/User";
import { loadingActions } from "../../redux/slides/loading.slide";
import userServices from "../../services/user.service";
import "./settings.module.scss";
const { Option } = Select;

export function SellerSettingsPage() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const ruleStringRequired = useRuleStringRequired();
  const [api, contextHolder] = notification.useNotification();
  const [profile, setProfile] = useState<User>();
  const [form] = Form.useForm();
  const [keySelected, setKeySelected] = useState("default");
  const [currencies, setCurrencies] = useState([]);
  const [disabledCurrencySelect, setDisabledCurrencySelect] = useState(true);
  const menus = [
    {
      key: "default",
      label: t("settings.account"),
    },
    {
      key: "legal-infor",
      label: t("settings.legalInfor"),
    },
    {
      key: "payment-details",
      label: t("settings.paymentDetails"),
    },
    {
      key: "term-of-payment",
      label: t("settings.termsNPayment"),
    },
    {
      key: "bulk-pricing",
      label: t("part.bulkPricing"),
    },
  ];
  const getMe = async () => {
    try {
      const rs = await userServices.me();
      setProfile(rs);
      form.setFieldsValue(rs);
    } catch (error) {
      // onLogout();
    }
  };
  const onFinish = async (values: User) => {
    dispatch(loadingActions.show(true));
    try {
      const rs = await userServices.updateAccount({
        ...profile,
        ...values,
      });
      setProfile(rs);
      api.success({
        message: `${t("success")}!`,
        description: `Updated Account`,
        placement: "topRight",
      });
    } catch (e: any) {
      api.error({
        message: e.error,
        description: e.message,
        placement: "topRight",
      });
    }
    dispatch(loadingActions.show(false));
  };

  const getCurrencyOptions = async () => {
    try {
      const rs = await userServices.getCurrencies();
      setCurrencies(rs.data);
    } catch (error) {}
  };

  useEffect(() => {
    if (currencies && profile) {
      setDisabledCurrencySelect(false);
      form.setFieldValue("currencyExchangeId", profile?.currencyExchange?.id);
    }
  }, [currencies, profile]);

  useEffect(() => {
    getMe();
    getCurrencyOptions();
  }, []);

  const onMenuSelected = (m: any) => {
    setKeySelected(m.key);
  };
  return (
    <>
      {contextHolder}
      <h3>{t("accountSettings")}</h3>
      <div className="b-settings">
        <div className="row">
          <div className="col col-12 col-lg d-lg-flex flex-lg-column gap-3 b-menus">
            {menus.map((m) => (
              <Button
                key={`m-${m.key}`}
                type={m.key === keySelected ? "primary" : "default"}
                shape="round"
                onClick={onMenuSelected.bind(null, m)}
              >
                {m.label}
              </Button>
            ))}
          </div>
          <div className="col col-12 col-lg-8 col-xl-10">
            {keySelected === "default" && (
              <FadeIn>
                <Form
                  className="app-form"
                  form={form}
                  layout="vertical"
                  name="basic"
                  onFinish={onFinish}
                  autoComplete="off"
                >
                  <div className="row">
                    <div className="col col-12 col-xl-6">
                      <p className="sub-l">{t("contactInfomation")}</p>
                      <div className="row">
                        <div className="col col-12 col-md-6">
                          <Form.Item
                            label={t("firstName")}
                            name="firstName"
                            rules={ruleStringRequired}
                          >
                            <Input maxLength={30} />
                          </Form.Item>
                        </div>
                        <div className="col col-12 col-md-6">
                          <Form.Item
                            label={t("lastName")}
                            name="lastName"
                            rules={ruleStringRequired}
                          >
                            <Input maxLength={30} />
                          </Form.Item>
                        </div>
                        <div className="col col-12">
                          <Form.Item name="gender" label={t("gender")}>
                            <Radio.Group>
                            <Radio.Button value={Gender.MALE}>
                              <ManOutlined /> {t("male")}
                            </Radio.Button>
                            <Radio.Button value={Gender.FEMALE}>
                              <WomanOutlined /> {t("female")}
                            </Radio.Button>
                            <Radio.Button value={Gender.NO_INFORMATION}>
                              <i className="bi bi-gender-ambiguous"></i> {t("other")}
                            </Radio.Button>
                            </Radio.Group>
                          </Form.Item>
                        </div>
                        <div className="col col-12">
                          <Form.Item
                            label={t("currency")}
                            name="currencyExchangeId"
                            rules={[{ required: true }]}
                          >
                            <Select
                              placeholder=""
                              disabled={disabledCurrencySelect}
                              loading={disabledCurrencySelect}
                            >
                              {currencies.map((currency: any) => (
                                <Option key={currency.id} value={currency.id}>
                                  {currency.isoCode} - {currency.symbol}
                                </Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </div>
                        <div className="col col-12 col-md-6">
                          <Form.Item
                            label={t("companyName")}
                            name="companyName"
                            rules={ruleStringRequired}
                          >
                            <Input maxLength={60} />
                          </Form.Item>
                        </div>
                        <div className="col col-12 col-md-6">
                          <Form.Item label={t("phone")} name="phone">
                            <Input maxLength={13} />
                          </Form.Item>
                        </div>
                        <div className="col col-12 col-md-6">
                          <Form.Item label={t("fax")} name="fax">
                            <Input maxLength={13} />
                          </Form.Item>
                        </div>
                        <div className="col col-12 col-md-6">
                          <Form.Item label={t("website")} name="website">
                            <Input maxLength={60} />
                          </Form.Item>
                        </div>
                      </div>
                    </div>
                    {/* <div className="col"></div> */}
                    <div className="col col-12 col-lg-6"></div>
                    <div className="col col-12 col-xl-6">
                      <AddressForm
                        form={form}
                        data={profile?.billingAddress}
                        title={t("billingAddress")}
                        name="billingAddress"
                      />
                    </div>

                    {/* <hr /> */}

                    <div className="col"></div>
                  </div>
                  <hr className="mt-3" />
                  <div>
                    <Button type="primary" htmlType="submit">
                      {t("saveChanges")}
                    </Button>
                  </div>
                </Form>
              </FadeIn>
            )}
            {keySelected === "legal-infor" && (
              <FadeIn>
                <Form
                  className="app-form"
                  form={form}
                  layout="vertical"
                  name="basic"
                  onFinish={onFinish}
                  autoComplete="off"
                >
                  <div className="row">
                    <div className="col col-12 col-md-4">
                      <p className="sub-l">{t("settings.legalInfor")}</p>
                      <Form.Item label="GF" name="legal">
                        <Input maxLength={60} />
                      </Form.Item>
                      <Form.Item label={t("settings.vatId")} name="vatId">
                        <Input maxLength={60} />
                      </Form.Item>
                      <Form.Item label={t("settings.taxNo")} name="taxNumber">
                        <Input maxLength={60} />
                      </Form.Item>
                    </div>
                    <div className="col"></div>
                  </div>
                  <hr className="mt-3" />
                  <div>
                    <Button type="primary" htmlType="submit">
                      {t("saveChanges")}
                    </Button>
                  </div>
                </Form>
              </FadeIn>
            )}
            {keySelected === "payment-details" && (
              <FadeIn>
                <Form
                  className="app-form"
                  form={form}
                  layout="vertical"
                  name="basic"
                  onFinish={onFinish}
                  autoComplete="off"
                >
                  <div className="row">
                    <div className="col col-12 col-md-4">
                      <p className="sub-l">{t("settings.paymentDetails")}</p>
                      <Form.Item label={t("settings.bankName")} name="bankName">
                        <Input maxLength={60} />
                      </Form.Item>
                      <Form.Item label="IBAN" name="iban">
                        <Input maxLength={60} />
                      </Form.Item>
                      <Form.Item label="BIC" name="bic">
                        <Input maxLength={60} />
                      </Form.Item>
                    </div>
                    <div className="col"></div>
                  </div>
                  <hr className="mt-3" />
                  <div>
                    <Button type="primary" htmlType="submit">
                      {t("saveChanges")}
                    </Button>
                  </div>
                </Form>
              </FadeIn>
            )}
            {keySelected === "term-of-payment" && (
              <FadeIn>
                <Form
                  className="app-form"
                  form={form}
                  layout="vertical"
                  name="basic"
                  onFinish={onFinish}
                  autoComplete="off"
                >
                  <div className="row">
                    <div className="col col-12 col-md-4">
                      <p className="sub-l">{t("settings.termsNPayment")}</p>
                      <Form.Item
                        label={t("settings.paymentTarget")}
                        name="paymentTarget"
                        rules={[{ required: true }]}
                      >
                        <InputNumber addonAfter="Tage" min={0} />
                      </Form.Item>
                    </div>
                    <div className="col"></div>
                  </div>
                  <hr className="mt-3" />
                  <div>
                    <Button type="primary" htmlType="submit">
                      {t("saveChanges")}
                    </Button>
                  </div>
                </Form>
              </FadeIn>
            )}
            {keySelected === "bulk-pricing" && (
              <FadeIn>
                <FormBulkPricingSetting
                  data={profile}
                  form={form}
                  onFinish={onFinish}
                />
              </FadeIn>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
