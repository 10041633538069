import { Alert, Button, Form, Input } from "antd";
import { configSelector } from "app/redux/slides/config.slide";
import { langSelector } from "app/redux/slides/locale.slide";
import { useRulePassword } from "app/utils/FormValidation";
import { useRef, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { loadingActions } from "../../redux/slides/loading.slide";
import userServices from "../../services/user.service";
import CountryAutocomplete from "../Address/CountryAutocomplete";
import RegisterConsent from "./RegisterConsent";

declare global {
  interface Window {
    grecaptcha: any;
  }
}

function RegisterForm() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const config = useSelector(configSelector);
  const lang = useSelector(langSelector);
  const [form] = Form.useForm();
  const [password, setPassword] = useState("");
  const [error, setError] = useState<any>();
  const [captchaToken, setCaptchaToken] = useState<string | undefined>();
  const [countryCode, setCountryCode] = useState<string>();
  const rulePassword = useRulePassword();
  const captchaRef: any = useRef(null);

  const onFinish = async (values: any) => {
    dispatch(loadingActions.show(true));
    try {
      setError(undefined);
      values.captchaResponse = captchaToken;
      values.locale = lang?.toUpperCase() || "DE";
      await userServices.register(values);
      navigate("/register/success");
    } catch (e: any) {
      if (e) {
        if (e?.exception.includes("DatabaseConstraintViolationException")) {
          e.message = t("error.register.userExisted");
        }
        setError(e);
      } else {
        setError(t("error.default"));
      }
    }
    dispatch(loadingActions.show(false));
  };
  const onReCaptchaVerify = (token: any) => {
    setCaptchaToken(token);
  };
  const onReCaptchaExpired = () => {
    setCaptchaToken(undefined);
  };
  const onCountrySelected = (value: any) => {
    form.setFieldValue(["billingAddress", "country"], value.country);
    form.setFieldValue(["billingAddress", "countryCode"], value.countryCode);
    setCountryCode(value.countryCode);
  };

  return (
    <Form form={form} className="app-form" name="basic" layout="vertical" onFinish={onFinish} autoComplete="off">
      {!!error && (
        <Alert
          message={error.message}
          type="error"
          closable
          showIcon
          className="mb-3"
        />
      )}
      <div className="row">
        <div className="col col-12">
          <Form.Item
            label={t("email")}
            name="email"
            rules={[{ required: true, type: "email" }]}
          >
            <Input autoComplete="new-user" maxLength={60} />
          </Form.Item>
        </div>
        <div className="col col-12 col-md-6">
          <Form.Item label={t("password")} name="password" rules={rulePassword}>
            <Input.Password
              autoComplete="new-password"
              onChange={(e) => setPassword(e.target.value)}
              maxLength={60}
            />
          </Form.Item>
        </div>
        <div className="col col-12 col-md-6">
          <Form.Item
            label={t("confirmPassword")}
            name="confirmPassword"
            rules={[
              {
                required: !!password,
                validator: async (_, confirmPassword) => {
                  if (password != confirmPassword) {
                    return Promise.reject(
                      new Error(t("validate.confirmPassword") || "")
                    );
                  }
                  return true;
                },
              },
            ]}
          >
            <Input.Password autoComplete="new-confpassword" maxLength={60} />
          </Form.Item>
        </div>
        <div className="col col-12 col-md-6">
          <Form.Item
            label={t("firstName")}
            name="firstName"
            rules={[{ required: true }]}
          >
            <Input maxLength={30} />
          </Form.Item>
        </div>
        <div className="col col-12 col-md-6">
          <Form.Item
            label={t("lastName")}
            name="lastName"
            rules={[{ required: true }]}
          >
            <Input maxLength={30} />
          </Form.Item>
        </div>
        <div className="col col-12 col-md-6">
          <Form.Item
            label={t("phone")}
            name="phone"
            rules={[{ required: true }]}
          >
            <Input type="tel" maxLength={30} />
          </Form.Item>
        </div>
        <div className="col col-12 col-md-6">
          <Form.Item
            label={t("companyName")}
            name="companyName"
            rules={[{ required: true }]}
          >
            <Input maxLength={100} />
          </Form.Item>
        </div>
        <div className="col col-12">
          <Form.Item label={t("vatNumber")} name="vatId">
            <Input maxLength={30} />
          </Form.Item>
        </div>
        <div className="col col-12">
          <Form.Item name={["billingAddress", "country"]} hidden>
            <Input />
          </Form.Item>
          <Form.Item name={["billingAddress", "countryCode"]} hidden>
            <Input />
          </Form.Item>
          <Form.Item
            label={t("country")}
            name={"country"}
            rules={[
              {
                required: true,
                validator: (_: any, value: any) => {
                  return countryCode
                    ? Promise.resolve()
                    : Promise.reject(new Error("Country code is missing"));
                },
              },
            ]}
          >
            <CountryAutocomplete
              name={["billingAddress", "country"]}
              onSelected={onCountrySelected}
            />
          </Form.Item>
        </div>
        <div className="col col-12">
          <RegisterConsent />
        </div>
        {config.captchaEnabled && (
          <Form.Item>
            <ReCAPTCHA
              ref={captchaRef}
              key={lang}
              sitekey={config.keySiteCaptcha || ""}
              onChange={onReCaptchaVerify}
              onExpired={onReCaptchaExpired}
              hl={lang}
            />
          </Form.Item>
        )}
        <Form.Item className="mt-1">
          <Button
            type="primary"
            htmlType="submit"
            block
            disabled={config.captchaEnabled && !captchaToken}
          >
            {t("register")}
          </Button>
        </Form.Item>
      </div>
    </Form>
  );
}

export default RegisterForm;
