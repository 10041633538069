import { createSlice } from "@reduxjs/toolkit";
import { ShopType } from "app/models/ShopType";

type StateType = {
  captchaEnabled: boolean;
  keySiteCaptcha: string;
  shopType?: ShopType;
  gaTrackingCode: string;
  gtmTrackingCode: string;
  sellerMail: string;
  supportMail: string;
  locales: string[];
  paymentEnabled: boolean;
  technicalDrawingRequired: boolean;
  subfolderFrontendUrl: string;
  shopCountryCode: string;
  shopCountryVatRate: string;
  shopMaterial: string;
  minimumOrderValue?: number;
  minimumOrderValueForFastDelivery?: number;
  activeEconomy: boolean;
  activeFast: boolean;
  activeStandard: boolean;
  activeStandardShipping?: boolean;
  activeSelfPickUpShipping?: boolean;
  activeSpecialShipping?: boolean;
  higherThresholdMachinePriceIndicator?: number;
  higherThresholdSheetMetalPriceIndicator?: number;
  lowerThresholdMachinePriceIndicator?:number;
  lowerThresholdSheetMetalPriceIndicator?: number;
};

const initialState: StateType = {
  captchaEnabled: false,
  keySiteCaptcha: "",
  shopType: undefined,
  gaTrackingCode: "",
  gtmTrackingCode: "",
  sellerMail: "",
  supportMail: "",
  locales: [],
  paymentEnabled: false,
  technicalDrawingRequired: false,
  subfolderFrontendUrl: "",
  shopCountryCode: "",
  shopCountryVatRate: "",
  shopMaterial: "",
  minimumOrderValue: undefined,
  minimumOrderValueForFastDelivery: undefined,
  activeEconomy: true,
  activeFast: true,
  activeStandard: true,
  activeStandardShipping: true,
  activeSelfPickUpShipping: true,
  activeSpecialShipping: true,
  higherThresholdMachinePriceIndicator: 0,
}

export const configSlice = createSlice({
  name: "config",
  initialState: initialState,
  reducers: {
    setData: (state, action) => {
      state.shopType = process.env.REACT_APP_SHOPTYPE
        ? process.env.REACT_APP_SHOPTYPE
        : action.payload.shopType;
      state.captchaEnabled = action.payload.captchaEnabled;
      state.keySiteCaptcha = action.payload.keySiteCaptcha;
      state.gaTrackingCode =
        process.env.REACT_APP_ENV == "production"
          ? action.payload.gaTrackingCode
          : "G-WCJLX4MR6R";
      state.gtmTrackingCode = action.payload.gtmTrackingCode;
      state.sellerMail = action.payload.sellerMail;
      state.supportMail = action.payload.supportMail || "support@gocad.de";
      state.locales = action.payload.locales;
      state.paymentEnabled = action.payload.paymentEnabled;
      state.technicalDrawingRequired = action.payload.technicalDrawingRequired;
      state.subfolderFrontendUrl = action.payload.subfolderFrontendUrl;
      state.shopCountryCode = action.payload.shopCountryCode;
      state.shopCountryVatRate = (
        (action.payload.shopCountryVatRate ?? 0) * 100
      ).toString();
      state.shopMaterial = action.payload.shopMaterial;
      state.minimumOrderValue = action.payload.minimumOrderValue;
      state.minimumOrderValueForFastDelivery =
        action.payload.minimumOrderValueForFastDelivery;
      state.activeEconomy = action.payload.activeEconomy;
      state.activeFast = action.payload.activeFast;
      state.activeStandard = action.payload.activeStandard;
      state.activeStandardShipping = action.payload.activeStandardShipping;
      state.activeSelfPickUpShipping = action.payload.activeSelfPickUpShipping;
      state.activeSpecialShipping = action.payload.activeSpecialShipping;
      state.lowerThresholdMachinePriceIndicator = action.payload.lowerThresholdMachinePriceIndicator;
      state.lowerThresholdSheetMetalPriceIndicator = action.payload.lowerThresholdSheetMetalPriceIndicator;
      state.higherThresholdMachinePriceIndicator = action.payload.higherThresholdMachinePriceIndicator;
      state.higherThresholdSheetMetalPriceIndicator = action.payload.higherThresholdSheetMetalPriceIndicator;
    },
  },
});

export const configActions = configSlice.actions;
export const configSelector = (state: any) => state.config;
export default configSlice.reducer;
