import { LinkOutlined, LoadingOutlined } from "@ant-design/icons";
import { CurrencyExchange } from "app/models/User";
import { UserRole } from "app/models/UserRole";
import { authSelector } from "app/redux/slides/auth.slide";
import { configSelector } from "app/redux/slides/config.slide";
import { projectActions } from "app/redux/slides/project.slide";
import Utils from "app/utils";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Project, ProjectStatus } from "../../models/Project";
import projectServices from "../../services/project.service";
import CurrencyFormat from "../Format/CurrencyFormat";
import PaymentForm from "../Payment/PaymentForm";
import PaymentFormModal from "../Payment/PaymentFormModal";
import ProjectListManualPaymentFormModal from "../Payment/ProjectListManualPaymentFormModal";
import PdfFileIcon from "../SVGs/PdfFileIcon";
import SurfaceTreatmentSurcharge from "./SurfaceTreatmentSurcharge";
import TotalPartPrice from "./TotalPartPrice";
import "./projectPrice.module.scss";
import { Price } from "app/models/Price";
import { ShippingOption } from "app/models/ShippingOption";
import { Order } from "app/models/Order";
import { DeliveryOption } from "app/models/DeliveryOption";

interface DefaultProps {
  project: Project;
  price?: Price;
  shippingOption?: ShippingOption;
  countryCode?: any;
  onCompletePayment?: any;
  accessCode?: string;
  onDownloadPdf?: any;
  deliveryOption?: DeliveryOption;
}
function ProjectPrices(props: DefaultProps) {
  const {
    project,
    price,
    shippingOption,
    countryCode,
    onDownloadPdf,
    accessCode,
    deliveryOption,
  } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [deliveryCost, setDeliveryCost] = useState(
    price ?? project.order?.price
  );
  const [downloadingPreviewOrder, setDownloadingPreviewOrder] = useState(false);
  const [triggerShowModal, setTriggerShowModal] = useState(false);
  const [showPayment, setShowPayment] = useState(false);
  const [showPaid, setShowPaid] = useState(false);
  const [showVat, setShowVat] = useState(false);
  const [showLoadingPaid, setLoadingPaid] = useState(false);
  const config = useSelector(configSelector);
  const auth = useSelector(authSelector);
  const [projectCurrency, setProjectCurrency] = useState<any>(null);
  const [shippingOptionData, setShippingOptionData] = useState(
    shippingOption ?? project.order?.shippingOption
  );
  const [hasFootnote, setHasFootnote] = useState(false);
  const [projectOrder, setProjectPaymentInfo] = useState<Order>(project.order);

  useEffect(() => {
    if (price) {
      setDeliveryCost(price);
    }
  }, [price]);

  useEffect(() => {
    setHasFootnote(false);
    if (
      (deliveryCost?.shipping ?? deliveryCost?.adaptedShipping) == null &&
      (shippingOption ?? project.order?.shippingOption) ==
        ShippingOption.SPECIAL
    ) {
      setHasFootnote(true);
    }
  }, [shippingOption, deliveryCost]);

  useEffect(() => {
    setDeliveryCost(price ?? project.order?.price);
    if (
      (countryCode ?? project?.order?.shippingAddress?.countryCode) ==
      config.shopCountryCode
    ) {
      setShowVat(true);
    } else {
      setShowVat(false);
    }
    if (project) {
      if (project.status != ProjectStatus.IN_PROGRESS) {
        checkPaymentStatus();
      }
      const currencyInfo: CurrencyExchange = {
        exchangeRate: deliveryCost?.currencyExchangeRate,
        symbol: deliveryCost?.currencySymbol,
        isoCode: deliveryCost?.currencyIsoCode,
      };
      setProjectCurrency(currencyInfo);
    }
  }, [project, countryCode]);

  const onDownloadPreviewOffer = async () => {
    try {
      setDownloadingPreviewOrder(true);
      if (onDownloadPdf) {
        await onDownloadPdf();
        setDownloadingPreviewOrder(false);
        return;
      }
      await projectServices.downloadPreviewOrder({
        id: project.id,
        name: project.name || "GOCAD",
      });
    } catch (error) {}
    setDownloadingPreviewOrder(false);
  };

  const handleOnCompletePayment = async () => {
    setTriggerShowModal(false);
    setShowPayment(false);
    setShowPaid(true);
    const rs: any = await getProject();
    setProjectPaymentInfo(rs?.order);
  };

  const getProject = async () => {
    let rs: any;

    if (accessCode) {
      rs = await projectServices.viewByCode(accessCode);
    } else if (project?.id) {
      rs = await projectServices.detail(project?.id);
    }

    return rs;
  };

  const checkPaymentStatus = async () => {
    if (!config?.paymentEnabled || !project?.id) return;

    try {
      setLoadingPaid(true);
      await Utils.waitForSeconds(3);

      const rs: any = await getProject();
      setProjectPaymentInfo(rs?.order);

      setLoadingPaid(false);
      if (!rs?.order?.paid) {
        setShowPayment(true);
        setShowPaid(false);
      } else {
        setShowPaid(true);
        setShowPayment(false);
      }
    } catch (error) {
      // console.log(error);
    }
  };

  const onShippingCostChange = async (value: any) => {
    if (project.id) {
      try {
        const rs = await projectServices.adaptShippingCost({
          projectId: project.id,
          shippingCost: value / projectCurrency.exchangeRate,
        });
        dispatch(projectActions.setProject(rs));
      } catch (error) {}
    }
  };

  if (!deliveryCost) return <></>;
  return (
    <div className="w-100 summary-price">
      <TotalPartPrice price={deliveryCost} currencyExchange={projectCurrency} />
      <SurfaceTreatmentSurcharge
        price={deliveryCost}
        currencyExchange={projectCurrency}
      />
      <div className="d-flex">
        <label>
          {t("price.deliveryOptionPrice")} (
          {t(`deliveryOption.${deliveryOption}`)})
        </label>
        <label className="ms-auto">
          <CurrencyFormat
            value={deliveryCost.deliveryOptionPrice}
            currencyExchange={projectCurrency}
          />
        </label>
      </div>
      <div className="d-flex">
        <label>{t("price.packagingPrice")}</label>
        <label className="ms-auto">
          <CurrencyFormat
            value={deliveryCost.packagingPrice}
            currencyExchange={projectCurrency}
          />
        </label>
      </div>
      <div className="d-flex shipping-cost">
        <label>{t("price.shipping")}</label>
        <label className={`ms-auto ${hasFootnote ? "footnote note-n-1" : ""}`}>
          {/* {projectAdaptShippingStatuses.includes(project.status) &&
            auth.user?.role !== UserRole.BUYER && (
              <>
                <InputCurrencyInline
                  name="shipping"
                  value={deliveryCost.adaptedShipping ?? deliveryCost.shipping}
                  callBack={onShippingCostChange}
                  adapted={deliveryCost.shippingAdapted}
                  currencyInfo={{
                    exchangeRate: deliveryCost?.currencyExchangeRate,
                    symbol: deliveryCost?.currencySymbol,
                    isoCode: deliveryCost?.currencyIsoCode,
                  }}
                  inputProps={{
                    min: 0,
                  }}
                />
              </>
            )} */}
          <>
            <CurrencyFormat
              value={deliveryCost.shipping}
              currencyExchange={projectCurrency}
              showZero
            />
          </>
        </label>
      </div>
      {/* <div className="text-muted">
        {auth.user?.role !== UserRole.BUYER && projectAdaptShippingStatuses.includes(project.status) && (project.order.price?.shipping ??
          project.order.price?.adaptedShipping) === null && (
          <Alert
            className="mb-2"
            type="warning"
            icon={<WarningOutlined />}
            showIcon
            message={t("validate.shippingCost")}
          />
        )}
      </div> */}
      <hr />
      <div className="d-flex fw-bold">
        <label>{t("price.netTotal")}</label>
        <label className="ms-auto">
          <CurrencyFormat
            value={deliveryCost.netTotal}
            currencyExchange={projectCurrency}
          />
        </label>
      </div>
      {(project?.order?.price?.discountAmount ?? 0) > 0 && (
        <>
          <hr />
          <div className="d-flex fw-bold">
            <label>{t("payment.discountAmountApplied")}</label>
            <label className="ms-auto">
              -
              <CurrencyFormat
                value={project?.order?.price?.discountAmount}
                currencyExchange={projectCurrency}
              />
            </label>
          </div>
        </>
      )}
      {showVat && (
        <div className="d-flex">
          <label>{t("price.vat", { vat: config.shopCountryVatRate })}</label>
          <label className="ms-auto">
            <CurrencyFormat
              value={deliveryCost.vat}
              currencyExchange={projectCurrency}
            />
          </label>
        </div>
      )}
      <hr />
      <div className="d-flex fw-bold">
        <label>{t("price.total")}</label>
        <label
          className={`ms-auto total-price ${
            hasFootnote ? "footnote note-n-1" : ""
          }`}
        >
          <CurrencyFormat
            value={deliveryCost.total}
            currencyExchange={projectCurrency}
          />
        </label>
      </div>
      {hasFootnote && (
        <div className="footnote">
          <div className="footnote-msg note-msg-1">
            {t(
              `shippingOption.SPECIAL.${
                auth.user?.role == UserRole.BUYER ? UserRole.BUYER : UserRole.SELLER
              }.footnote`
            )}
          </div>
        </div>
      )}
      <hr />
      <div className="row">
        <div className="col col-md-9 col-xs-12">
          <a
            onClick={onDownloadPreviewOffer}
            className={downloadingPreviewOrder ? "isDisabled " : ""}
            style={{ textDecoration: "none" }}
          >
            <PdfFileIcon /> {(project?.status == ProjectStatus.SELLER_APPROVED || project?.status == ProjectStatus.BUYER_APPROVED) ? t("project.checkout.previewOrder") : t("project.checkout.previewOffer")}
            {downloadingPreviewOrder && (<>{" "}<LoadingOutlined spin /></>)}
          </a>
        </div>
        <div className="col col-md-3 col-xs-12" style={{ textAlign: "right" }}>
          {showLoadingPaid && <LoadingOutlined />}
        </div>
      </div>
      {auth?.user?.role == UserRole.SELLER &&
        projectOrder &&
        (projectOrder?.paymentComment ||
          projectOrder?.payment?.paymentIntentId) && (
          <>
            <hr />
            <div className="row">
              <div className="d-flex">
                <p className="mb-2" style={{ fontSize: 22, fontWeight: 600 }}>
                  {t("payment.paymentDetails")}
                </p>
              </div>
              {projectOrder?.payment?.paymentIntentId && (
                <div>
                  <a
                    href={`${process.env.REACT_APP_STRIPE_PAYMENT_LINK}/${projectOrder?.payment?.paymentIntentId}`}
                    target="_black"
                    className="text-camelcase text-link"
                  >
                    <img src="/images/stripe.svg" width={25} />{" "}
                    {t("payment.stripePaymentDetails")} {""} <LinkOutlined />
                  </a>
                </div>
              )}
              {projectOrder?.paymentComment && (
                <div className="">
                  <p className="text-camelcase fw-bold mb-1">
                    {t("payment.note")}:
                  </p>
                  <p className="">{projectOrder?.paymentComment ?? "--"}</p>
                </div>
              )}
            </div>
          </>
        )}
      {showPayment && auth?.user?.role == UserRole.BUYER && (
        <div className="row mt-2">
          <div className="col col-md-12 col-xs-12 btn-pay-full-width">
            <PaymentFormModal
              triggerShowModal={triggerShowModal}
              project={project}
            >
              <PaymentForm
                onCompletePayment={handleOnCompletePayment}
                project={project}
              ></PaymentForm>
            </PaymentFormModal>
          </div>
        </div>
      )}
      {showPayment &&
        !projectOrder?.paid &&
        auth?.user?.role == UserRole.SELLER && (
          <div className="row mt-2">
            <div className="col col-md-12 col-xs-12 btn-pay-full-width">
              <ProjectListManualPaymentFormModal
                project={project}
                onComplete={handleOnCompletePayment}
              />
            </div>
          </div>
        )}
      {showPaid && <div className="paid-stamp"></div>}
    </div>
  );
}
export default ProjectPrices;
