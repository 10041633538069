import { Card } from "antd";
import { HTMLAttributes } from "react";
import { useTranslation } from "react-i18next";

interface DefaultProps extends HTMLAttributes<any> {
  labelTranslateKey?: string;
}

function GroupLayout(props: DefaultProps) {
  const { t } = useTranslation();
  const { children, className, labelTranslateKey = "" } = props;
  return (
    <Card className={`${className} d-group`}>
      <p className="text-label group-title">
        {t(labelTranslateKey, {
          defaultValue: "",
        })}
      </p>
      <div>{children}</div>
    </Card>
  );
}

export default GroupLayout;
