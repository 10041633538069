import { Address } from "./Address";
export interface User {
  id?: number;
  email?: string;
  password?: string;
  firstName?: string;
  lastName?: string;
  companyName?: string;
  verificationCode?: string;
  bankName?: string;
  bic?: string;

  discount?: number;
  fax?: string;
  gender?: Gender;
  iban?: string;
  legal?: string;
  paymentTarget?: number;
  phone?: string;
  billingAddress?: Address;
  shippingAddress?: Address;
  taxNumber?: string;
  vatId?: string;
  website?: string;

  name?: string;
  numParts?: number;
  orderCostSum?: number;
  numCalculations?: number;
  calculationEnabled?: boolean;

  quantityRef1?: any;
  quantityRef2?: any;
  quantityRef3?: any;

  locale?: string;
  enabled?: boolean;
  currencyExchange?: CurrencyExchange;
}

export enum Gender {
  MALE = "MALE",
  FEMALE = "FEMALE",
  NO_INFORMATION = "NO_INFORMATION",
}

export interface CurrencyExchange {
  id?: number;
  symbol?: string;
  exchangeRate: number | 1;
  currency?: string;
  locale?: string;
  isoCode?: string;
}