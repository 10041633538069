import AuthBrecht from "app/components/Template/AuthBrecht";
import AuthDefault from "app/components/Template/AuthDefault";
import AuthEnsinger from "app/components/Template/AuthEnsinger";
import AuthGM from "app/components/Template/AuthGM";
import AuthHertenberger from "app/components/Template/AuthHertenburger";
import AuthIrmscher from "app/components/Template/AuthIrmscher";
import AuthKurios from "app/components/Template/AuthKurios";
import AuthLST247 from "app/components/Template/AuthLST247";
import AuthRich from "app/components/Template/AuthRich";

export function AuthPage() {
  switch (process.env.REACT_APP_BRAND) {
    case "ensinger":
      return <AuthEnsinger />;
    case "lst247":
      return <AuthLST247 />;
    case "brecht":
      return <AuthBrecht />;
    case "hertenberger":
      return <AuthHertenberger />;
    case "kurios":
      return <AuthKurios />;
    case "rich":
      return <AuthRich />;
    case "gm":
      return <AuthGM />;
    case "irmscher":
      return <AuthIrmscher />;
    default:
      return <AuthDefault />;
  }
}
