import { Alert, Button, Form, Input, Modal, notification } from "antd";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  authActions,
  authSelector,
  loginAsync,
} from "../../redux/slides/auth.slide";
import { loadingActions } from "../../redux/slides/loading.slide";
import userServices from "../../services/user.service";
// import reCAPTCHA from "../../utils/reCAPTCHA";
import { configSelector } from "app/redux/slides/config.slide";
import { langSelector } from "app/redux/slides/locale.slide";
import ReCAPTCHA from "react-google-recaptcha";

const LoginForm = () => {
  const { t } = useTranslation();
  const auth = useSelector(authSelector);
  const config = useSelector(configSelector);
  const lang = useSelector(langSelector);
  const dispatch = useDispatch();
  const [forgotForm] = Form.useForm();
  const [api, contextHolder] = notification.useNotification();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [error, setError] = useState();
  const [requestingForgotPassword, setRequestingForgotPassword] =
    useState(false);
  const [captchaToken, setCaptchaToken] = useState<string | undefined>();
  const captchaRef: any = useRef(null);

  useEffect(() => {
    dispatch(loadingActions.show(false));
    if (!auth.user) {
      dispatch(authActions.clear());
    }
  }, []);

  const onFinish = async (values: any) => {
    // const token = await reCAPTCHA.getToken(reCAPTCHA.LOGIN_ACTION);
    values.captchaResponse = captchaToken;
    dispatch(loginAsync(values));
  };
  const onReCaptchaVerify = (token: any) => {
    setCaptchaToken(token);
  };
  const onReCaptchaExpired = () => {
    setCaptchaToken(undefined);
  };

  const showModal = () => {
    setError(undefined);
    setIsModalOpen(true);
  };

  const onSubmitForgot = async ({ email }: any) => {
    dispatch(loadingActions.show(true));
    setRequestingForgotPassword(true);
    try {
      await userServices.forgotPassword(email);
      openSentForgotPassword(email);
      handleCancel();
    } catch (e: any) {
      setError(e);
    }
    dispatch(loadingActions.show(false));
    setRequestingForgotPassword(false);
  };

  const handleCancel = () => {
    forgotForm.resetFields();
    setIsModalOpen(false);
  };

  const openSentForgotPassword = (email: string) => {
    api.info({
      duration: 5000,
      message: t("forgotPassword.success.title"),
      description: (
        <div>
          {t("forgotPassword.success.msg1")}
          <br />
          {t("forgotPassword.success.msg2")}
        </div>
      ),
      placement: "topRight",
    });
  };

  return (
    <>
      {contextHolder}
      <Form
        className="app-form"
        name="basic"
        layout="vertical"
        // labelCol={{ span: 8 }}
        // wrapperCol={{ span: 16 }}
        initialValues={{ remember: true }}
        onFinish={onFinish}
        autoComplete="off"
      >
        {auth.errorMsg && (
          <div
            className="alert alert-danger alert-dismissible fade show"
            role="alert"
          >
            {t("error.login")}
          </div>
        )}
        <Form.Item
          label={t("email")}
          name="username"
          rules={[{ required: true, type: "email" }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label={t("password")}
          name="password"
          rules={[{ required: true }]}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item>
          <a className="text-primary fw-semibold" onClick={showModal}>
            {t("forgotPassword")}
          </a>
        </Form.Item>
        {config.captchaEnabled && (
          <Form.Item>
            <ReCAPTCHA
              className="mx-auto"
              ref={captchaRef}
              key={lang}
              sitekey={config.keySiteCaptcha || ""}
              onChange={onReCaptchaVerify}
              onExpired={onReCaptchaExpired}
              hl={lang}
            />
          </Form.Item>
        )}
        <Form.Item className="mt-3">
          <Button
            type="primary"
            htmlType="submit"
            block
            disabled={config.captchaEnabled && !captchaToken}
          >
            {t("login")}
          </Button>
        </Form.Item>
      </Form>
      <Modal
        title={t("forgotPassword")}
        centered
        open={isModalOpen}
        onOk={forgotForm.submit}
        onCancel={handleCancel}
        okButtonProps={{
          loading: requestingForgotPassword,
        }}
        cancelButtonProps={{
          disabled: requestingForgotPassword,
        }}
        maskClosable={false}
      >
        <Form
          form={forgotForm}
          name="basic"
          layout="vertical"
          onFinish={onSubmitForgot}
          autoComplete="off"
          disabled={requestingForgotPassword}
        >
          {error && (
            <Alert
              className="mb-3"
              message={t("error.forgotPassword.invalidEmail")}
              type="error"
              closable
              showIcon
            />
          )}
          <Form.Item
            label={t("email")}
            name="email"
            rules={[{ required: true, type: "email" }]}
          >
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default LoginForm;
