/* eslint-disable no-script-url */
import { Modal } from "antd";
import { consents } from "app/constants/consent.constant";
import { authSelector } from "app/redux/slides/auth.slide";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const getPrivatePolicyUrl = () => {
  switch (process.env.REACT_APP_BRAND) {
    case "lst247":
      return "https://lst-beuren.de/datenschutz.html";
    default:
      return "";
  }
};

function AppInfo(props: any) {
  const { t } = useTranslation();
  const auth = useSelector(authSelector);
  const [isOpenPrivacyPolicy, setIsOpenPrivacyPolicy] = useState(false);

  if (process.env.REACT_APP_BRAND === "lst247") {
    return (
      <>
        <div className={`app-version ${auth.isLogined ? "logined" : ""}`}>
          <span>
            powered by{" "}
            <a href="https://gocad.de/" target="_blank" rel="noreferrer">
              goCAD.
            </a>
            &nbsp;|&nbsp;
            <a
              rel="noreferrer"
              target="_blank"
              className="ms-auto"
              href="https://lst247.de/"
            >
              Zur LST247-Homepage
            </a>
          </span>
          <br />
          <span>
            <a
              rel="noreferrer"
              target="_blank"
              className="ms-auto"
              href="https://lst-beuren.de/datenschutz.html"
            >
              {t("privacyPolicy")}
            </a>{" "}
            &nbsp;|&nbsp;
            <a
              rel="noreferrer"
              target="_blank"
              className="ms-auto"
              href="https://lst247.de/#Kontakt-Footer"
            >
              {t("contact")}
            </a>
            &nbsp;|&nbsp;
            <a
              rel="noreferrer"
              target="_blank"
              className="ms-auto"
              href="https://lst247.de/impressum.html"
            >
              {t("imprint")}
            </a>
          </span>
        </div>
      </>
    );
  } else if (process.env.REACT_APP_BRAND === "gm") {
    return (
      <>
        <div className={`app-version ${auth.isLogined ? "logined" : ""}`}>
          <span>
            <a
              href="javascript:void(0)"
              className="ms-auto"
              onClick={setIsOpenPrivacyPolicy.bind(null, true)}
            >
              {t("privacyPolicy")}
            </a>
          </span>
        </div>
        <Modal
          className={`modal-body-scroll ${process.env.REACT_APP_BRAND}`}
          width={"90vw"}
          open={isOpenPrivacyPolicy}
          onCancel={setIsOpenPrivacyPolicy.bind(null, false)}
          footer={null}
          destroyOnClose
        >
          <div
            className="privacy-policy"
            dangerouslySetInnerHTML={{
              __html:
                consents[process.env.REACT_APP_BRAND || ""]?.privacyPolicy,
            }}
          ></div>
        </Modal>
      </>
    );
  }

  return (
    <div className={`app-version ${auth.isLogined ? "logined" : ""}`}>
      powered by{" "}
      <a href="https://gocad.de/" target="_blank" rel="noreferrer">
        goCAD.
      </a>
    </div>
  );
}

export default AppInfo;
