import { CheckOutlined, UndoOutlined } from "@ant-design/icons";
import {
  Button,
  DatePicker,
  Form,
  FormProps,
  Input,
  Popover,
  Space,
} from "antd";
import { Project } from "app/models/Project";
import dayjs from "dayjs";
import moment from "moment";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import "./adaptDeliveryDateForm.module.scss";
import { RangePickerProps } from "antd/es/date-picker";

interface DefaultProps {
  project: Project;
  packageDeliveryTime?: number;
  onSubmit?: any;
}

function AdaptDeliveryDateForm(props: DefaultProps) {
  const { project, onSubmit, packageDeliveryTime = 14 } = props;
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [submitting, setSubmitting] = useState(false);
  const [isOpenOriginalDeliveryDate, setIsOpenOriginalDeliveryDate] =
    useState(false);
  const [adaptDeliveryDate, setAdaptDeliveryDate] = useState(
    project?.order.adaptedDeliveryDate || project?.order.deliveryDate
  );
  const disabledDate: RangePickerProps["disabledDate"] = (current) => {
    const value =
      moment().endOf("day") >
      moment(project.createdAt).endOf("day").add(packageDeliveryTime, "days")
        ? moment()
        : moment(project.createdAt)
            .endOf("day")
            .add(packageDeliveryTime, "days");
    return current && current < value;
  };
  const onAdaptDeliveryDateChange = (e: any) => {
    setAdaptDeliveryDate(e.format("YYYY-MM-DD"));
  };
  const onAdaptReset = () => {
    form.setFieldValue("deliveryDate", dayjs(project?.order.deliveryDate));
    setAdaptDeliveryDate(project?.order.deliveryDate);
  };
  const onFinish = async (values: any) => {
    setSubmitting(true);
    try {
      if (onSubmit) await onSubmit(values);
    } catch (error) {}
    setSubmitting(false);
  };
  return (
    <Form
      className="app-form delivery-date-inline"
      form={form}
      initialValues={{
        projectId: project.id,
        deliveryDate:
          project.order?.adaptedDeliveryDate || project.order?.deliveryDate
            ? dayjs(
                project.order?.adaptedDeliveryDate ||
                  project.order?.deliveryDate
              )
            : "",
      }}
      disabled={submitting}
      onFinish={onFinish}
    >
      <Form.Item name="projectId" hidden>
        <Input />
      </Form.Item>
      <Space.Compact>
        {!!project.order?.deliveryDate && (
          <Form.Item className="mb-0">
            <Button
              type="default"
              icon={<UndoOutlined />}
              disabled={project.order.deliveryDate === adaptDeliveryDate}
              onClick={onAdaptReset}
            />
          </Form.Item>
        )}
        <Popover
          placement="left"
          content={
            <>
              <p className="mb-2 fw-bold">{t("originalDeliveryDate")}</p>
              {project.order?.deliveryDate &&
                moment(project.order.deliveryDate).format("L")}
            </>
          }
          trigger="hover"
          open={isOpenOriginalDeliveryDate}
        >
          <Form.Item
            className="hidden-label d-flex"
            label={t("project.deliveryDate")}
            name="deliveryDate"
            rules={[{ required: true }]}
          >
            <DatePicker
              disabled={project?.archived}
              style={{ width: "7vw" }}
              allowClear={false}
              format={moment.localeData().longDateFormat("L")}
              onFocus={setIsOpenOriginalDeliveryDate.bind(null, true)}
              onBlur={setIsOpenOriginalDeliveryDate.bind(null, false)}
              onChange={onAdaptDeliveryDateChange}
              disabledDate={disabledDate}
            />
          </Form.Item>
        </Popover>
        <Form.Item className="mb-0">
          <Button
            type="primary"
            htmlType="submit"
            loading={submitting}
            icon={<CheckOutlined />}
            disabled={
              (project.order.adaptedDeliveryDate ||
                project.order.deliveryDate) === adaptDeliveryDate
            }
          />
        </Form.Item>
      </Space.Compact>
    </Form>
  );
}

export default AdaptDeliveryDateForm;
