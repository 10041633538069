import { Checkbox, Form, Modal } from "antd";
import { consents } from "app/constants/consent.constant";
import { langSelector } from "app/redux/slides/locale.slide";
import $ from "jquery";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import "./registerConsent.module.scss";

const brandName = process.env.REACT_APP_LOGO == "logo-elastx" ? "Elastx24" : "Plastx24"

function RegisterConsent() {
  const { t } = useTranslation();
  const lang = useSelector(langSelector);
  const [isOpenTnC, setIsOpenTnC] = useState(false);
  const [isOpenPrivacyPolicy, setIsOpenPrivacyPolicy] = useState(false);
  let consent1Hidden = false;
  let consent2Hidden = false;
  let consent3Hidden = false;
  const isHidden = () => {
    switch (process.env.REACT_APP_BRAND) {
      case "hertenberger":
      case "brecht":
      case "lst247":
      case "ensinger":
      case "rich":
      case "kurios":
      case "gm":
      case "jakobdreherei":
        return false;
      default:
        return true;
    }
  };

  const hasConsent4 = () => {
    switch (process.env.REACT_APP_BRAND) {
      case "lst247":
      case "brecht":
      case "gm":
      case "jakobdreherei":
        return true;
      default:
        return false;
    }
  };

  useEffect(() => {
    const btnTnC = $("#btn-tnc");
    const btnPrivacyPolicy = $("#btn-privacy-policy");
    if (btnTnC) {
      btnTnC.on("click", () => {
        setIsOpenTnC(true);
      });
    }
    if (btnPrivacyPolicy) {
      btnPrivacyPolicy.on("click", () => {
        setIsOpenPrivacyPolicy(true);
      });
    }
  }, [lang]);

  if (isHidden()) return <></>;

  return (
    <>
      <Form.Item
        label={t("register.consent.title")}
        name="consent"
        valuePropName="checked"
        rules={[
          {
            validator: async (_, value) => {
              if (!consent1Hidden && !consent3Hidden &&
                (!value ||
                value.indexOf("consent1") < 0 ||
                value.indexOf("consent3") < 0)
              ) {
                return Promise.reject(new Error(t("validate.tnc") || ""));
              }
              return true;
            },
          },
          {
            validator: async (_, value) => {
              if (hasConsent4() && value?.indexOf("consent4") < 0) {
                return Promise.reject(
                  new Error(t("validate.confirmBussiness") || "")
                );
              }
              return true;
            },
          },
        ]}
      >
        <Checkbox.Group className="d-flex flex-column gap-2">
          {!consent1Hidden && <Checkbox value="consent1">
            <span
              className="ant-form-item-required"
              dangerouslySetInnerHTML={{
                __html: t(
                  `register.consent.${process.env.REACT_APP_BRAND}.content1`,
                  {
                    defaultValue: t("register.consent.default.content1")
                  }
                ),
              }}
            ></span>
          </Checkbox>}
          {!consent2Hidden && <Checkbox className="ms-0" value="consent2">
            <span
              dangerouslySetInnerHTML={{
                __html: t(
                  `register.consent.${process.env.REACT_APP_BRAND}.content2`,
                  {
                    defaultValue: t("register.consent.default.content2"),
                    brand: brandName
                  }
                ),
              }}
            ></span>
          </Checkbox>}
          {!consent3Hidden && <Checkbox className="ms-0" value="consent3">
            <span
              className="ant-form-item-required"
              dangerouslySetInnerHTML={{
                __html: t(
                  `register.consent.${process.env.REACT_APP_BRAND}.content3`, {
                    defaultValue: t("register.consent.default.content3"),
                  }
                ),
              }}
            ></span>
          </Checkbox>}
          {hasConsent4() && (
            <Checkbox className="ms-0" value="consent4">
              <span
                className="ant-form-item-required"
                dangerouslySetInnerHTML={{
                  __html: t(
                    `register.consent.${process.env.REACT_APP_BRAND}.content4`
                  , {
                    defaultValue: t("register.consent.default.content4")
                  }),
                }}
              ></span>
            </Checkbox>
          )}
        </Checkbox.Group>
      </Form.Item>
      <Modal
        className={`modal-body-scroll ${process.env.REACT_APP_BRAND}`}
        width={"90vw"}
        open={isOpenTnC}
        onCancel={setIsOpenTnC.bind(null, false)}
        footer={null}
        destroyOnClose
      >
        <div
          className="tnc"
          dangerouslySetInnerHTML={{
            __html: consents[process.env.REACT_APP_BRAND || ""]?.tnc,
          }}
        ></div>
      </Modal>
      <Modal
        className={`modal-body-scroll ${process.env.REACT_APP_BRAND}`}
        width={"90vw"}
        open={isOpenPrivacyPolicy}
        onCancel={setIsOpenPrivacyPolicy.bind(null, false)}
        footer={null}
        destroyOnClose
      >
        <div
          className="privacy-policy"
          dangerouslySetInnerHTML={{
            __html: consents[process.env.REACT_APP_BRAND || ""]?.privacyPolicy,
          }}
        ></div>
      </Modal>
    </>
  );
}

export default RegisterConsent;
