import { CheckOutlined, UndoOutlined } from "@ant-design/icons";
import {
    Button,
    DatePicker,
    Form,
    Input,
    notification,
    Popover,
    Space,
} from "antd";
import dayjs from "dayjs";
import moment from "moment";
import { useEffect, useState } from "react";
import { ArrowLeft } from "react-bootstrap-icons";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import CadFileItem from "../../components/Part/CadFileItem/CadFileItem";
import { PartViewMode } from "../../models/PartViewMode";
import { Project, ProjectStatus } from "../../models/Project";
import { authSelector } from "../../redux/slides/auth.slide";
import {
    projectActions,
    projectSelector,
} from "../../redux/slides/project.slide";
import projectServices from "../../services/project.service";
import { useTranslation } from "react-i18next";
import { getRouterByRole } from "app/utils";

export function ProjectAdaptPage() {
  const [api, contextHolder] = notification.useNotification();
  const { t } = useTranslation();
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const auth = useSelector(authSelector);
  const { data } = useSelector(projectSelector);
  const [adaptForm] = Form.useForm();
  const [project, setProject] = useState<Project>(data);
  const [loadingAdaptDeliveryDate, setLoadingAdaptDeliveryDate] =
    useState(false);
  const [isOpenOriginalDeliveryDate, setIsOpenOriginalDeliveryDate] =
    useState(false);
  const [adaptDeliveryDate, setAdaptDeliveryDate] = useState(
    project.order.adaptedDeliveryDate || project.order.deliveryDate
  );
  const [resetAdaptLoading, setResetAdaptLoading] = useState(false);

  const getProject = async () => {
    try {
      const rs: Project = await projectServices.detail(id);
      setProject(rs);
      dispatch(projectActions.setProject(rs));
      if (rs.status === ProjectStatus.IN_PROGRESS) {
        navigate(`/${getRouterByRole(auth.user?.role)}/projects/${id}/calculations`);
        return;
      }
    } catch (error) {
      navigate(`/${getRouterByRole(auth.user?.role)}`);
    }
  };
  useEffect(() => {
    if (!project) {
      getProject();
      return;
    }
    if (
      project.status !== ProjectStatus.CHECKOUTED &&
      project.status !== ProjectStatus.REQUESTED_OFFER
    ) {
      navigate(`/${getRouterByRole(auth.user?.role)}`);
    }
  }, []);
  const onAdaptDeliveryDate = async (values: any) => {
    setLoadingAdaptDeliveryDate(true);
    try {
      const rs = await projectServices.adaptDeliveryDate({
        projectId: id,
        deliveryDate: values.deliveryDate.format("YYYY-MM-DD"),
      });
      setProject(rs);
      dispatch(projectActions.setProject(rs));
      api.info({
        message: `Adapt delivery date`,
        description: (
          <>
            Change delivery date to{" "}
            {moment(values.deliveryDate.format("YYYY-MM-DD")).format("L")}!
          </>
        ),
        placement: "topRight",
      });
    } catch (error) {
      api.error({
        message: `Adapt delivery date`,
        description: <>Failed!</>,
        placement: "topRight",
      });
    }
    setLoadingAdaptDeliveryDate(false);
  };
  const onAdaptDeliveryDateChange = (e: any) => {
    setAdaptDeliveryDate(e.format("YYYY-MM-DD"));
  };
  const onAdaptReset = () => {
    adaptForm.setFieldValue("deliveryDate", dayjs(project.order.deliveryDate));
    setAdaptDeliveryDate(project.order.deliveryDate);
  };
  const onResetOriginal = async () => {
    setResetAdaptLoading(true);
    try {
      const rs = await projectServices.resetAdapt(project.id);
      projectActions.setProject(rs);
      setProject(rs);
      navigate(`/${getRouterByRole(auth.user?.role)}/projects/${id}`);
    } catch (error) {}
    setResetAdaptLoading(false);
  };

  if (!project) return <></>;
  return (
    <>
      {contextHolder}
      <div className="d-flex">
        <Link to={`/${getRouterByRole(auth.user?.role)}/projects/${id}`}>
          <ArrowLeft /> Back to project
        </Link>
        <Button
          className="ms-auto"
          type="primary"
          onClick={onResetOriginal}
          loading={resetAdaptLoading}
        >
          Reset Adapt
        </Button>
      </div>
      <h6 className="mt-3 fw-bold">{t("project.deliveryDate")}</h6>
      <Form
        form={adaptForm}
        className="app-form mb-3"
        initialValues={{
          projectId: id,
          deliveryDate: dayjs(
            project.order?.adaptedDeliveryDate || project.order?.deliveryDate
          ),
        }}
        onFinish={onAdaptDeliveryDate}
        disabled={loadingAdaptDeliveryDate}
      >
        <Form.Item name="projectId" hidden>
          <Input />
        </Form.Item>
        <Space.Compact>
          <Form.Item className="mb-0">
            <Button
              type="default"
              icon={<UndoOutlined />}
              disabled={project.order.deliveryDate === adaptDeliveryDate}
              onClick={onAdaptReset}
            />
          </Form.Item>
          <Popover
            placement="left"
            content={
              <>
                <p className="mb-2 fw-bold">{t("originalDeliveryDate")}</p>
                {moment(project.deliveryDate).format("L")}
              </>
            }
            trigger="hover"
            open={isOpenOriginalDeliveryDate}
          >
            <Form.Item name="deliveryDate" className="mb-0">
              <DatePicker
                allowClear={false}
                onFocus={setIsOpenOriginalDeliveryDate.bind(null, true)}
                onBlur={setIsOpenOriginalDeliveryDate.bind(null, false)}
                onChange={onAdaptDeliveryDateChange}
                format={moment.localeData().longDateFormat("L")}
              />
            </Form.Item>
          </Popover>
          <Form.Item className="mb-0">
            <Button
              type="primary"
              htmlType="submit"
              loading={loadingAdaptDeliveryDate}
              icon={<CheckOutlined />}
              disabled={project.order.adaptedDeliveryDate === adaptDeliveryDate}
            />
          </Form.Item>
        </Space.Compact>
      </Form>
      <h6 className="mt-1 fw-bold">{t("parts")}</h6>
      {project.parts?.map((item) => (
        <CadFileItem
          key={`part-${item.id}`}
          part={item}
          mode={PartViewMode.ADAPT}
        />
      ))}
    </>
  );
}
